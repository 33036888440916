/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0
}

.no-wrap {
  white-space: nowrap;
}

@import 'front/variable';
@import 'front/theme';
@import 'front/fonts';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';

/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #004691 !important;
}

.cdk-drop-list.list {
  border: 1px solid #004691 !important;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left !important;
}

@media screen and (max-width: 1318px) {
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    white-space: nowrap; //no break line
    padding: 0 10px !important;
  }

  .table-coverage {
    th.mat-header-cell {
      padding: 0 !important;
    }
  }
}

.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-sm {
  @media (max-width: 959px) {
    flex-direction: column !important;
  }
}

.flex-column-gt-sm {
  @media (max-width: 1368px) {
    flex-direction: column !important;
  }
}

.flex-column-gt-md {
  @media (max-width: 1465px) {
    flex-direction: column !important;
  }
}

.gap-15 {
  gap: 15px;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.gap-20 {
  gap: 20px;
}

.gap-80 {
  gap: 80px;
}

.gap-45 {
  gap: 45px;
}

.gap-xs-none {
  @media (max-width: 599px) {
    gap: 0;
  }
}

.mw-55 {
  max-width: 55%;
}


.full-width-table {
  width: 100%;
}

button.mat-raised-button {
  span.mat-ripple {
    background: none !important;
  }
}

.btn-lg {
  height: 70%;
  width: 170px;
  color: #004691 !important;
  border-radius: 10px !important;
  @media (max-width: 1444px) {
    height: 50px;
  }
}

.color-primary {
  color: #004691 !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.mat-title {
  &.faq {
    font-size: 26px;

    strong {
      font-size: 29px;
    }
  }

  &.big {
    font-size: 26px;
  }
}

.justify-center {
  justify-content: center;
}


.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: end;
}

.cursor-pointer {
  cursor: pointer;
}

.background-white {
  background-color: #fff;
}

.color-white {
  color: #fff !important;
}

.justify-between {
  justify-content: space-between !important;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 13px !important;
}

.transform-icon {
  transform: scale(0.8);
}

.w-100 {
  width: 100% !important;
}

.w-100-xs {
  @media (max-width: 599px) {
    width: 100% !important;
  }
}

.w-100-sm {
  @media (max-width: 959px) {
    width: 100% !important;
  }
}

.w-100-md {
  @media (max-width: 1280px) {
    width: 100% !important;
  }
}

.w-50-md {
  @media (max-width: 1280px) {
    width: 50%;
  }
}

.w-100-gt-sm {
  @media (max-width: 1368px) {
    width: 100%;
  }
}

.w-100-lg {
  @media (max-width: 1550px) {
    width: 100%;
  }
}

//.inner-card {
//  overflow: auto;
//  //max-height: 550px;
//}


.max-w-md {
  @media (max-width: 1280px) {
    max-width: 100% !important;
  }
}

.max-w-lg {
  @media (max-width: 1550px) {
    max-width: 100% !important;
  }
}

.gap-10-md {
  @media (max-width: 1280px) {
    gap: 10px;
  }
}

.gap-15-md {
  @media (max-width: 1280px) {
    gap: 15px;
  }
}

.gap-15-sm {
  @media (max-width: 959px) {
    gap: 15px;
  }
}

.gap-30-md {
  @media (max-width: 1280px) {
    gap: 30px;
  }
}

.gap-30-sm {
  @media (max-width: 959px) {
    gap: 30px;
  }
}

.gap-30-lg {
  @media (max-width: 1550px) {
    gap: 30px;
  }
}

.inner-side {
  padding-left: 40px !important;
}


.tab-custom {
  .mat-ripple.mat-tab-label.mat-focus-indicator {
    background-color: #004691 !important;
    color: #EB0028 !important;
    width: 50%;
    height: 50px;
    font-size: 16px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    opacity: 1;

    &.mat-tab-label-active {
      background-color: #EB0028 !important;
      color: #004691 !important;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}

.mat-tab-label-active {
  height: 60px !important;
}

.mat-tab-labels {
  align-items: end;
}


.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: #004691 !important;
}

.flex-wrap {
  flex-wrap: wrap;
  display: flex;
}

.page_bg-secondary {
  &.bg {
    background-color: #EFF3F8;
  }

  @media (max-width: 962px) {
    padding: calc(5rem / 4) !important;
  }
}

.mat-dialog-container {
  @media (max-width: 962px) {
    max-height: 615px !important;
  }
}

.mat-dialog-title {
  padding: 20px;
  background-color: #004691;

  h3 {
    color: #ffffff;
  }
}

.show-divider-sm {
  display: none;
  @media (max-width: 959px) {
    display: block !important;
  }
}

.break-all {
  word-break: break-all;
}

.datepicker-extract {
  .mat-ripple {
    background: none !important;
  }
}

.paginator-nocolor {
  .mat-ripple {
    background: none !important;
  }
}

.mat-list-item.selected > .mat-list-item-content > a {
  border-radius: 0 !important;
  background-color: #EB0028 !important;
  font-weight: bold !important;

  span {
    color: #ffffff !important;
  }

  svg-icon {
    svg {
      fill: #ffffff !important;
      stroke: #ffffff !important;
    }
  }
}

.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}

.delete-btn {
  display: flex;
  height: 40px;
  width: 140px;
  max-width: 80px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #940101;

  svg-icon {
    fill: #940101;

    svg{
      width: 20px;
    }
  }
}

.add-btn {
  svg-icon {
    margin-right: 5px;
  }

  cursor: pointer;
  display: flex;
  height: 40px;
  width: 140px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #004691;

  span {
    font-size: 14px;
    color: #004691;
  }
}


.option {
  background-color: #EFEFEF;
  padding: 11px 16px;

  &.offers {
    padding: 11px 16px 11px 0;
  }

  border-radius: 5px;
  margin: 6px 0;
}

.add-button {
  background-color: #004691;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  height: 36px;
  width: 36px;

  mat-icon {
    margin-left: 1px;
  }
}

.delete {
  background-color: #EB0028;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.eye {
  background-color: #FAB932;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.view {
  background-color: #7FA2C8;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.duplicate {
  background-color: #7FA2C8;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.edit {
  background-color: #004691;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;

  svg-icon {
    svg {
      stroke: #ffffff !important;
    }
  }
}

.edit-circle {
  svg-icon {
    svg {
      stroke: #004691 !important;
    }

  }

  background-color: #FAB932;
  padding: 1px 7px;
  border-radius: 100%;

}

.delete-circle {
  background-color: #EB0028;
  padding: 1px 7px;
  border-radius: 100%;
}

.duplicate-circle {
  background-color: #DFDFDF;
  padding: 1px 7px;
  border-radius: 100%;
}

.option-circle {
  background-color: #DFDFDF;
  padding: 1px 7px;
  border-radius: 100%;
}


.svg-back {
  svg {
    transform: rotate(90deg);
  }
}


.svg-white {
  svg {
    stroke: #ffffff !important;
    fill: #FFFFFF !important;
  }
}

.svg-red {
  svg {
    stroke: #EB0728 !important;
  }
}

.dialog-card {
  width: 100%;
  padding: 5px;
  border-radius: 15px;
  background-color: #EFF3F8;
}

.cdk-overlay-pane {
  //width: 66vw !important;
  @media (max-width: 600px) {
    width: 95vw !important;
  }
}


.dialog-subtitle {
  color: #848484;
  font-weight: bold;
}

.logo-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  min-width: 300px;
  height: 300px;
  background-color: #ffffff;
  align-items: center !important;
  justify-content: center !important;
}

.logo-card-small {
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;
  width: 110px;
  height: 60px;
  background-color: #ffffff;

  &.user-highlight {
    width: 70px;
    height: 70px;
    margin-bottom: 0;
  }

  .img-card {
    height: 100% !important;
  }

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.logo-card-medium {
  padding: 5px;
  border-radius: 5px;
  width: 200px;
  height: 60px;
  background-color: #ffffff;

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
  }
}

.logo-card-big {
  padding: 5px;
  border-radius: 5px;
  width: 305px;
  height: 165px;
  background-color: #ffffff;
  margin-bottom: 30px;
  justify-content: center;

  &.highlight {
    width: 200px !important;
    min-height: 300px !important;
  }

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
  }
}


.img-card {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #DFDFDF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.user-highlight {
    height: 100%;
    width: 100%;
  }

  &.highlight-content {
    height: 90%;
  }

  span {
    color: #848484;
    font-weight: bold;
  }
}

.cancel-button {
  width: 120px;
  height: 60px;
  background-color: transparent !important;
  border: 2px solid #004691 !important;
  color: #004691 !important;
}

.save-button {
  width: 120px;
  height: 60px;
  background-color: #004691 !important;
  border: 2px solid #004691 !important;
  color: #ffffff !important;
}


.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  //color: #ffffff !important;
  border-radius: 10px !important;

}

.mat-form-field-appearance-outline .mat-form-field-outline {
  //color: #ffffff !important;
  border-radius: 10px !important;
}

.divider-row {
  width: 100%;
  height: 1px;
  background-color: #004691;
  margin-top: 10px;
  margin-bottom: 20px;
}

.mat-elevation-z8 {
  box-shadow: none !important;
  overflow-x: auto;
  width: 100%;
}

table.mat-table {
  border-collapse: initial;
  border-spacing: 0;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

mat-paginator {
  border-radius: 0 0 15px 15px;
}

#snav .mat-list-item a {
  margin-bottom: 0 !important;
}

.mat-divider {
  border-top-color: #014691 !important;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.th-no-padding {
  padding: 0;
  @media (max-width: 1320px) {
    padding: 0 !important;
  }
}


th.mat-header-cell {
  white-space: nowrap;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

.align-items-start {
  align-items: start;
}

.tab-card {
  &-top {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: end;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
      border-radius: 20px 20px 0 0;
      background-color: #EAEAEA;
      width: 50%;
      height: 50px;

      span {
        color: #014691;
      }

      &.active {
        height: 70px;
        padding: 20px;

        span {
          font-weight: bold;
          color: #ffffff;
        }

        svg-icon {
          svg {
            fill: #FAB932 !important;
            stroke: #FAB932 !important;
          }
        }

        background-color: #014691;
      }
    }
  }

  &-content {
    background-color: #ffffff;
    //padding: 20px 20px 0 20px;
  }
}

.treat-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.is-cursor {
  cursor: pointer;
}

.table-hide-button {
  &-item {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .hover-buttons:hover {
    .table-hide-button-item {
      opacity: 1;
      visibility: visible;
    }
  }
}

.high-hide-button {
  &-item {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  .hover-buttons:hover {
    .high-hide-button-item {
      opacity: 1;
      visibility: visible;
    }
  }
}


.campaign-inner {
  &.dark {
    background-color: #DFDFDF;
  }

  padding-right: 100px;
  padding-left: 100px;
  @media(max-width: 800px) {
    padding: 0
  }
}


.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;

    &-service {
      margin: 1px 5px 0;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.circle-radius {
  border-radius: 50%;
}

.high-hide-button {
  .edit,
  .delete,
  .duplicate-move,
  .option,
  .add-btn,
  .view,
  .eye {
    transition: transform 0.5s ease;
  }

  .add-btn {
    transform: translateX(85px);
  }

  &:hover {
    .eye {
      transform: translateX(-20px);
    }

    .edit {
      transform: translateX(-15px);
    }

    .duplicate-move {
      transform: translateX(-15px);
    }

    .delete {
      transform: translateX(-10px);
    }

    .add-btn {
      transform: translateX(-20px);
    }

    .view {
      transform: translateX(-20px);
    }
  }
}

.outline-button {
  background-color: transparent !important;
  border: 2px solid #004691 !important;
  color: #004691 !important;
}

.cdk-overlay-pane {
  min-height: 380px !important;
}

.disabled-click {
  pointer-events: none;

  .delete {
    background-color: #EFEFEF;
  }
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-black {
  background-color: #080808 !important;
}

.bg-gray {
  background-color: #EFF3F8 !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.w-50 {
  width: 50%;
}

.radius-bottom-right-10 {
  border-radius: 0 0 10px 0 !important;
}

.radius-bottom-left-10 {
  border-radius: 0 0 0 10px !important;
}

.radius-top-right-10 {
  border-radius: 0 10px 0 0 !important;
}

.radius-top-left-10 {
  border-radius: 10px 0 0 0 !important;
}

.radius-top-10 {
  border-radius: 10px 10px 0 0 !important;
}

.radius-bottom-10 {
  border-radius: 0 0 10px 10px !important;
}

.text-align-end {
  text-align: end;
}

.filter-gray-bg {
  filter: contrast(75%) brightness(115%);
}

.dark-mode-bg {
  background-color: #030722;
}

.z-index-1 {
  z-index: 1;
}

.focus-offer {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  height: 52px;
  align-items: center;

  span {
    font-weight: bold;
    color: #014691;
  }

  @media(max-width: 700px) {
    margin-bottom: 10px;
  }
}

.mat-tab-body-wrapper {
  .mat-tab-body-content {
    padding-top: 30px;
    overflow-x: hidden;
  }
}

button:disabled,
[disabled] {
  opacity: 0.5;
}

.slick-prev:before, .slick-next:before {
  color: $secondary !important;
}

.slick-prev, .slick-next {
  top: 30% !important;
}


/* the slides */
.slick-slide {
  margin: 0 27px;
}

/* the parent */
.slick-list {
  margin: 0 -27px;
}

.slick-track {
  margin-left: 0 !important;
}

.offers {
  .offers-top > div {
    @media (max-width: 1465px) {
      gap: 12px;
      width: 100% !important;
      align-items: center !important;
      justify-content: center;
    }
  }
  .manage{
    justify-content: center;
  }
}

.filter-option{
  &[disabled]{
    opacity: 1 !important;
  }
  .mat-pseudo-checkbox{
    opacity: 0 !important;
  }
}

.page-content{
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
    padding: 0 5px;
  }
}

.list-thumbs{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  &-item{
    width: 25%;
    text-align: center;
  }

  &-label{
    width: 100%;
    height: 30px;
    padding: 0 1px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.sc-vrqbdz-8.eLaznq{
  display: none;
}
